import { is, forEachObjIndexed } from 'ramda';
import numeral from 'numeral';
import DateTimePresenter from 'presenters/DateTimePresenter';

const Converters = {
  bool: (key, object) => object[key],
  moment: (key, object) => DateTimePresenter.formatUTC(object[key]),
  object: (key, object) => object[key],
  string: (key, object) => object[key],
  array: (key, object) => object[key],
  integer: (key, object) => {
    const value = object[key];
    return value === null ? null : parseInt(value, 10);
  },
  float: (key, object) => {
    const value = object[key];
    return value === null ? null : parseFloat(value);
  },
  currency(key, object) {
    return numeral(object[key]).format('$0,0[.]00');
  },
  nullableInt: (key, object) => {
    const value = object[key];
    return value === null ? null : value;
  },

  toBool: (key, object) => !!object[key],
};

class UnsupportedPresenterType {
  constructor(type, name) {
    this.name = `Unsuported presenter type '${type}' for field '${name}'`;
  }
}

export default class Presenter {
  constructor(methods) {
    forEachObjIndexed((method, name) => {
      if (is(String, method)) {
        if (is(Function, Converters[method])) {
          this[name] = Converters[method].bind(this, name);
        } else {
          throw new UnsupportedPresenterType(method, name);
        }
      } else {
        this[name] = method;
      }
    }, methods);
  }
}

const TypesConsts = {};
forEachObjIndexed((value, key) => {
  TypesConsts[key] = key;
}, Converters);
export const Types = TypesConsts;
